import React from 'react';
import { Router, Switch, Route } from 'react-router-dom';

import './App.css';
import LandingPage from '../LandingPage/LandingPage';
import ProcesarLoginCidi from '../ProcesarLoginCIdi/ProcesarLoginCidi';

import { createBrowserHistory } from 'history';
const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL
});

function App() {
  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route 
          path="/procesar-login-cidi"
          component={({location}) => <ProcesarLoginCidi location={location}/>} />
      </Switch>
    </Router>
  );
}

export default App;
